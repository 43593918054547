import React, { MutableRefObject, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import { Checkbox } from 'baseui/checkbox';
import { ClosedReasons } from '../closed-reasons-axios-service/closed-reasons-axios-service';

interface ListItemLabelProps {
  closedReason: ClosedReasons;
  closedReasons: ClosedReasons[];
  index: number;
  isClosedReasonsEnabled: number;
  handleInputBlur: (
    event: React.FocusEvent<HTMLInputElement, Element>,
    index: number,
  ) => void;
  handleCheckboxChange: (index: number) => void;
}

export const ListItemLabel = ({
  closedReason,
  index,
  isClosedReasonsEnabled,
  handleInputBlur,
  handleCheckboxChange,
  closedReasons
}: ListItemLabelProps) => {
  const [css] = useStyletron();
  const { reason, allowAdditionalDetails } = closedReason;
  const [localInputValue, setLocalInputValue] = useState(reason);
  const maxLength = 200;
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index:number) => {
    const value = event.target.value;
    setLocalInputValue(value);
    closedReasons[index].isInErrorState = value.length === maxLength;
  };
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        fontWeight: '400',
        color: isClosedReasonsEnabled ? '#908E8F' : '#CDCDCD',
      })}
      data-testid={`list-item-${index}`}
    >
      <div
        className={css({
          display: 'flex',
          width: '50%',
          marginRight: '16px',
        })}
      >
        {/*  Input component
                  See baseweb documentation how to add copy feature for the DND list: https://baseweb.design/guides/understanding-overrides/#override-the-entire-subcomponent
                  */}
        <div
          className={css({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <Input
          maxLength={maxLength}
            value={localInputValue}
            required={true}
            placeholder="Add a reason..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
            onBlur={(e) => handleInputBlur(e, index)}
            disabled={!isClosedReasonsEnabled}
            overrides={{
              Root: {
                style: {
                  border: localInputValue.length === 0 || localInputValue.length === maxLength ? '1px solid red' : 'inherit',
                  borderRadius: '10px'
                },
              },
            }}
          />
        </div>
      </div>
      <div style={{
            position: 'relative',
            width: '20%',
            display: 'flex',
            alignItems: 'left',
          }}>
      {localInputValue.length === maxLength &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <i
              className="fa fa-exclamation-circle"
              style={{
                color: 'red',
              }}
            ></i>
            <p style={{
              marginTop: '14px',
              marginLeft: '5px',
              color: 'red',
              fontFamily: 'Source Sans Pro',
              fontSize: '15px',
              fontStyle: 'italic',
              fontWeight: 400,
              lineHeight: '12.57px',

          }}
            >{`Entry cannot exceed ${maxLength} characters`}</p>
          </div>
            }
      </div>
      <Checkbox
        checked={allowAdditionalDetails}
        onChange={() => handleCheckboxChange(index)}
        onMouseDown={(e) => e.stopPropagation()}
        disabled={!isClosedReasonsEnabled}
        overrides={{
          Checkmark: {
            style: () => {
              const borderColor =
                allowAdditionalDetails && isClosedReasonsEnabled
                  ? '#3693bf'
                  : allowAdditionalDetails
                  ? '#D9D9D9'
                  : '#D9D9D9';
              return {
                backgroundColor:
                  allowAdditionalDetails && isClosedReasonsEnabled
                    ? '#3693bf'
                    : allowAdditionalDetails
                    ? '#D9D9D9'
                    : '#ffffff',
                borderTopColor: borderColor,
                borderRightColor: borderColor,
                borderBottomColor: borderColor,
                borderLeftColor: borderColor,
                marginLeft: '155px',
                borderTopStyle: 'solid',
                borderRightStyle: 'solid',
                borderBottomStyle: 'solid',
                borderLeftStyle: 'solid',
              };
            },
          },
          Input: {
            props: {
              'data-testid': `checkmark-${index}`,
            },
          },
        }}
      />
    </div>
  );
};
