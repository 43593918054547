import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import {
  validateHeaderName,
  validateHeaderValue,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { InputBox } from '../configurations-display/InputBox';
import { Checkbox } from 'baseui/checkbox';
import {
  ButtonCancelOverride,
  ButtonOverride,
  CheckboxCheckedOverride,
  CheckboxDisabledOverride,
  CheckboxUncheckedOverride,
  ModalHeaderOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { AddButton } from './AddButton';
import { StatefulTooltip } from 'baseui/tooltip';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Button } from 'baseui/button';
import { Redirect } from '../configurations-display/Redirect';

export const CustomHeadersEditor = ({
  allHeaders,
  setAllHeaders,
}: {
  allHeaders: any[];
  setAllHeaders: (value: any[]) => void;
}) => {
  const [css] = useStyletron();
  const [allDisplayedHeaders, setAllDisplayedHeaders] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [headerToDelete, setHeaderToDelete] = useState(null);

  useEffect(() => {
    const displayedHeaders = allHeaders
      .filter((header) => {
        if (
          header.source === 'headers' ||
          header.source === 'sensitiveHeadersMasked'
        ) {
          return true;
        }
        if (header.source === 'sensitiveHeaders') {
          const hasMaskedDuplicate = allHeaders.some(
            (h) =>
              h.key === header.key && h.source === 'sensitiveHeadersMasked',
          );
          return !hasMaskedDuplicate;
        }
        return false;
      })
      .map((header) => ({
        ...header,
        isSensitive: ['sensitiveHeaders', 'sensitiveHeadersMasked'].includes(
          header.source,
        ),
        readOnly: header.source === 'sensitiveHeadersMasked',
      }));

    if (displayedHeaders.length === 0) {
      displayedHeaders.push({ key: '', value: '', source: 'headers' });
    }

    setAllDisplayedHeaders(displayedHeaders);
  }, [allHeaders]);

  const handleInputChange = (index, field, value) => {
    const newDisplayedHeaders = [...allDisplayedHeaders];
    newDisplayedHeaders[index][field] = value.trim();
    setAllDisplayedHeaders(newDisplayedHeaders);

    const newHeaders = newDisplayedHeaders.filter(
      (header) => header.key.trim() || header.value.trim(),
    );

    // Ensure that pre-existing sensitive headers (which are not displayed) persist in allHeaders
    const sensitiveHeaders = allHeaders.filter(
      (header) =>
        header.source === 'sensitiveHeaders' &&
        newDisplayedHeaders.find(
          (h) => h.key === header.key && h.source === 'sensitiveHeadersMasked',
        ),
    );

    const mergedHeaders = [...newHeaders, ...sensitiveHeaders];
    setAllHeaders(mergedHeaders);
  };

  const handleCheckboxChange = (index, checked) => {
    const newHeaders = [...allDisplayedHeaders];
    newHeaders[index].isSensitive = checked;
    newHeaders[index].source = checked ? 'sensitiveHeaders' : 'headers';
    setAllDisplayedHeaders(newHeaders);
    const sensitiveHeaders = allHeaders.filter(
      (header) =>
        header.source === 'sensitiveHeaders' &&
        newHeaders.find(
          (h) => h.key === header.key && h.source === 'sensitiveHeadersMasked',
        ),
    );
    const mergedHeaders = [...newHeaders, ...sensitiveHeaders];
    setAllHeaders(mergedHeaders);
  };

  const handleDelete = (index) => {
    const keyToDelete = allDisplayedHeaders[index].key;

    const newDisplayedHeaders = allDisplayedHeaders.filter(
      (header) => header.key !== keyToDelete,
    );
    setAllDisplayedHeaders(newDisplayedHeaders);

    const newHeaders = allHeaders.filter(
      (header) => header.key !== keyToDelete,
    );

    setAllHeaders(newHeaders);
  };

  const addHeaders = () => {
    const newDisplayedHeaders = [
      ...allDisplayedHeaders,
      { key: '', value: '', source: 'headers' },
    ];
    setAllDisplayedHeaders(newDisplayedHeaders);
  };

  const allHeadersValid =
    allDisplayedHeaders.length <= 10 &&
    allDisplayedHeaders.every(
      (header, index) =>
        header.key &&
        header.value &&
        validateHeaderName(header.key, allDisplayedHeaders, index) === '' &&
        (header.source === 'headers' || header.source === 'sensitiveHeaders'
          ? validateHeaderValue(header.value) === ''
          : true),
    );

  return (
    <>
      {/* Delete Header Confirmation: */}
      <Modal
        onClose={() => setIsConfirmationOpen(false)}
        isOpen={isConfirmationOpen}
        overrides={{
          Dialog: {
            style: {
              marginTop: '75px',
              width: '30%',
            },
          },
        }}
      >
        <ModalHeader style={ModalHeaderOverride.style}>
          Are you sure you want to delete this header?
        </ModalHeader>
        <ModalBody>This action cannot be undone.</ModalBody>
        <ModalFooter>
          <div style={{ float: 'right', paddingBottom: '20px' }}>
            <Button
              onClick={() => setIsConfirmationOpen(false)}
              overrides={{
                Root: {
                  ...ButtonCancelOverride,
                  style: {
                    ...ButtonCancelOverride.style,
                    backgroundColor: 'white',
                  },
                },
              }}
              kind="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDelete(headerToDelete);
                setIsConfirmationOpen(false);
              }}
              overrides={{
                BaseButton: {
                  ...ButtonOverride,
                  style: {
                    ...ButtonOverride.style,
                    height: '42px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  },
                },
              }}
            >
              DELETE HEADER
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Custom Headers Editor: */}
      <div
        className={css({
          marginTop: '10px',
        })}
      >
        <Redirect
          link="https://relay-network.document360.io/docs/push-event-webhook"
          value="View Relay Integration Documentation"
        />
        {allDisplayedHeaders.map((header, index) => (
          <div
            key={index}
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            {allHeaders.some(
              (i) => i.key === header.key && i.value === header.value,
            ) && (
              <div
                style={{
                  borderLeft: '3px solid #d9d9d9',
                  height: '140px',
                  marginRight: '10px',
                }}
              />
            )}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div>
                <InputBox
                  title="Header Name"
                  value={header.key}
                  placeholder="Header Name"
                  onChange={(value) => handleInputChange(index, 'key', value)}
                  readOnly={header.readOnly}
                  validate={(value) => {
                    return validateHeaderName(
                      value,
                      allDisplayedHeaders,
                      index,
                    );
                  }}
                />
                <InputBox
                  title="Header Value"
                  value={header.value}
                  placeholder="Header Value"
                  onChange={(value) => handleInputChange(index, 'value', value)}
                  readOnly={header.readOnly}
                  validate={(value) => {
                    return validateHeaderValue(value);
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Checkbox
                  checked={header.isSensitive}
                  onChange={(event) =>
                    handleCheckboxChange(
                      index,
                      (event.target as HTMLInputElement).checked,
                    )
                  }
                  overrides={
                    header.source === 'sensitiveHeadersMasked'
                      ? { Checkmark: CheckboxDisabledOverride }
                      : header.isSensitive
                      ? { Checkmark: CheckboxCheckedOverride }
                      : { Checkmark: CheckboxUncheckedOverride }
                  }
                  disabled={header.readOnly}
                >
                  <div>
                    <span
                      style={{
                        color: '#5b5a59',
                        opacity:
                          header.source === 'sensitiveHeadersMasked' ? 0.5 : 1,
                      }}
                    >
                      This is a sensitive header.
                    </span>
                    <StatefulTooltip
                      accessibilityType={'tooltip'}
                      content={`Sensitive header values will be encrypted and masked in the UI after saving.`}
                      showArrow
                      placement="top"
                    >
                      <i
                        className="fa fa-question-circle"
                        style={{
                          fontSize: '16px',
                          marginLeft: '10px',
                          color: '#908E8F',
                        }}
                      ></i>
                    </StatefulTooltip>
                  </div>
                </Checkbox>
                {header.isSensitive &&
                  header.source !== 'sensitiveHeadersMasked' && (
                    <div
                      style={{
                        textAlign: 'right',
                        color: '#F1BA43',
                        fontSize: '14px',
                      }}
                    >
                      <i
                        className="fa fa-exclamation-circle"
                        style={{
                          marginRight: '10px',
                        }}
                      ></i>
                      <span style={{ fontStyle: 'italic' }}>
                        Once saved, this header will remain sensitive.
                      </span>
                    </div>
                  )}
              </div>
            </div>
            {allHeaders.some(
              (i) => i.key === header.key && i.value === header.value,
            ) && (
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  paddingLeft: '10px',
                }}
              >
                <i
                  onClick={() => {
                    setHeaderToDelete(index);
                    setIsConfirmationOpen(true);
                  }}
                  // onClick={() => handleDelete(index)}
                  className="fa fa-trash"
                  style={{
                    fontSize: '16px',
                    color: '#3693bf',
                    cursor: 'pointer',
                  }}
                ></i>
              </div>
            )}
          </div>
        ))}
        <div
          style={{ opacity: allHeadersValid ? 1 : 0.5 }}
          onClick={() => {
            if (allHeadersValid) {
              addHeaders();
            }
          }}
        >
          <AddButton text={'Add Another Header'} />
        </div>
      </div>
    </>
  );
};
