import React, { useEffect, useState } from 'react';
import { RnRadioGroup } from '@app/react-baseweb/baseweb-custom-components/rn-baseweb-radio';
import { useStyletron } from 'styletron-react';
import { useForm } from 'react-hook-form';
import { Textarea } from 'baseui/textarea';
import {
  ConfigurationFooter,
  InputTitle,
} from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import { SfdcConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { PushAPIService } from '@app/api-mgmt/components/push-api/push-api-service';
import { useClientInfo } from '@app/api-mgmt/components/push-api/context/ClientInfoContext';
import {
  validateName,
  validateSaleforceConsumerKey,
  validateSaleforceUsername,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import { InputBox } from '@app/api-mgmt/components/push-api/components/configurations-display/InputBox';
import { Button } from 'baseui/button';
import {
  ButtonCancelOverride,
  ButtonOverride,
  CheckboxCheckedOverride,
  CheckboxUncheckedOverride,
  TextAreaOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { ButtonIconRight } from '@app/api-mgmt/components/push-api/styles/AddConfigurationButtonStyles';
import { CertificateDownload } from '@app/api-mgmt/components/push-api/components/certificate-modal/CertificateDownload';
import { SelectBox } from '@app/api-mgmt/components/push-api/components/configurations-display/SelectBox';
import { OptionsT, Value } from 'baseui/select';
import { Checkbox } from 'baseui/checkbox';

export interface SfdcFormData {
  salesforce_version?: any;
  name: string;
  uid: string;
  active: boolean;
  queue_name: string;
  salesforce_username: string;
  salesforce_subdomain: 'login' | 'test';
  salesforce_consumer_key: string;
}

export const SfdcEditor = ({
  onSaveClicked,
  onCancelClicked,
  integration,
}: {
  onSaveClicked?: (newIntegration: SfdcConfiguration) => void;
  onCancelClicked?: () => void;
  integration?: SfdcConfiguration;
}) => {
  const [css] = useStyletron();
  const [{ clientId }] = useClientInfo();

  const defaultFormData = {
    uid: integration?.uid ?? '',
    versionValue: integration?.salesforce_version ?
      [{ label: integration?.salesforce_version, id: integration?.salesforce_version }] : [],
    name: integration?.name ?? '',
    active: integration?.active ?? true,
    queue_name: integration?.queue_name ?? '',
    salesforce_username: integration?.salesforce_username ?? '',
    salesforce_subdomain: integration?.salesforce_subdomain ?? 'test',
    salesforce_consumer_key: integration?.salesforce_consumer_key ?? '',
    public_key: integration?.public_key ?? '',
    newapi: (integration?.newapi !== undefined) ? integration.newapi : true,
  };

  const [versionList, setVersionList] = React.useState<OptionsT>(null);
  const [versionValue, setVersionValue] = React.useState<Value>(
    defaultFormData.versionValue,
  );
  const [name, setName] = useState(defaultFormData.name);
  const [username, setUsername] = useState(defaultFormData.salesforce_username);
  const [subdomain, setSubdomain] = useState<'test' | 'login'>(
    defaultFormData.salesforce_subdomain,
  );
  const [consumerKey, setConsumerKeys] = useState(
    defaultFormData.salesforce_consumer_key,
  );

  // some values are dependant on the form input values. Those are initalized here
  // and linked to the form input data.
  const [isValid, setIsValid] = useState(false);

  const [ newapi, setNewapi ] = useState(defaultFormData.newapi);

  useEffect(() => {
    setIsValid(
      validateName(name, integration?.name, integrationNames) === '' &&
      validateSaleforceUsername(username) === '' &&
      validateSaleforceConsumerKey(consumerKey) === '',
    );
  }, [name, username, subdomain, consumerKey]);

  // some values need to be pulled from the backend, so they're initialized here
  const [sfdcEventList, setSfdcEventList] = React.useState<string[]>([]);
  const [integrationNames, setIntegrationNames] = useState([]);
  useEffect(() => {
    PushAPIService.getEventSubscriptionList('sfdc').then((fetchedEvents) => {
      setSfdcEventList(fetchedEvents);
    });
    PushAPIService.getIntegrationNames(clientId).then((integrationNames) => {
      setIntegrationNames(integrationNames);
    });
  }, []);

  const { reset } = useForm<SfdcFormData>({
    defaultValues: defaultFormData,
  });

  /**
   * Clears the form data
   */
  const cancel = () => {
    // reset data that was entered when modal closes
    reset(defaultFormData);
    // callback to close the modal
    onCancelClicked();
  };

  const checkIfActive = () => {
    if (integration && integration.active === true) {
      return true;
    }
  };

  /**
   * Handles when data is submitted to the form (e.g. click Save or press
   * Enter).
   */
  const onSubmit = ({ activate }: { activate: boolean }) => {
    if (isValid) {
      const submission = {
        uid: integration?.uid ?? '',
        queue_name: integration?.queue_name ?? '',
        public_key: integration?.public_key ?? '',
        salesforce_version: versionValue[0]?.id as string,
        name,
        salesforce_username: username,
        salesforce_subdomain: subdomain,
        salesforce_consumer_key: consumerKey,
        type: 'sfdc',
        active: activate,
        newapi,
      };
      onSaveClicked(submission);
    }
  };

  const formatVersionOptions = (versions: string[]) => {
    const versionOptions = [];

    if (versions.length > 0) {
      versions.forEach((version) => {
        versionOptions.push({
          label: version,
          id: version,
        });
      });
    } else {
      versionOptions.push({
        label: '',
        id: '',
      });
    }

    setVersionList(versionOptions as OptionsT);

    if (integration?.salesforce_version) {
      setVersionValue(defaultFormData.versionValue);
    } else {
      setVersionValue(versionOptions[0] as Value);
    }
  };

  async function fetchVersions() {
    const fetchedVersions = await PushAPIService.getSfdcVersions('false');
    formatVersionOptions(fetchedVersions);
  }

  useEffect(() => {
    fetchVersions();
  }, []);

  const isExistingIntegration = integration?.uid && integration?.uid !== '';

  return (
    <div
      className={css({
        margin: 'auto',
      })}
    >
      <Redirect
        link="https://relay-network.document360.io/docs/salesforcecom"
        value="View Relay Integration Documentation"
      />
      <SelectBox
        placeholder=""
        title="Version"
        options={versionList}
        value={versionValue}
        onChange={(value) => setVersionValue(value)}
      />
      <InputBox
        title="Name"
        value={name}
        placeholder="Integration Name"
        validate={(value) =>
          validateName(value, integration.name, integrationNames)
        }
        onChange={setName}
        preValidate={isExistingIntegration}
      />
      <InputBox
        placeholder=""
        title="Salesforce Username"
        subtitle="sub"
        value={username}
        validate={validateSaleforceUsername}
        onChange={setUsername}
        preValidate={isExistingIntegration}
      />
      <InputTitle>
        <label>Salesforce Subdomain</label>
        <span style={{ fontSize: '16px' }}> (aud)</span>
      </InputTitle>

      <RnRadioGroup
        value={subdomain}
        options={[
          { value: 'test', label: 'Test' },
          { value: 'login', label: 'Login' },
        ]}
        onChange={(e) => setSubdomain(e)}
      ></RnRadioGroup>
      <InputBox
        placeholder=""
        title="Salesforce Consumer Key"
        subtitle="iss"
        value={consumerKey}
        validate={validateSaleforceConsumerKey}
        onChange={setConsumerKeys}
        preValidate={isExistingIntegration}
      />
      <InputTitle>
        <label>Events</label>
      </InputTitle>

      <Textarea
        overrides={{
          InputContainer: {
            style: {
              ...TextAreaOverride.style,
              height: '360px',
            },
          },
        }}
        value={sfdcEventList.join('\n')}
        disabled={true}
      />
      {defaultFormData.public_key && (
        <CertificateDownload
          publicKey={defaultFormData.public_key}
          integrationName={defaultFormData.name}
          clientId={clientId}
        />
      )}
      <ConfigurationFooter>
        <div style={{ float: 'left' }}>
          <Button
            type="reset"
            kind="tertiary"
            onClick={cancel}
            overrides={{ Root: ButtonCancelOverride }}
          >
            Cancel
          </Button>
        </div>
        <div style={{ float: 'right', opacity: isValid ? '100%' : '50%' }}>
          <Button
            onClick={() => onSubmit({ activate: false })}
            kind="tertiary"
            overrides={{ Root: ButtonCancelOverride }}
          >
            {checkIfActive() ? 'Save & Deactivate' : 'Save & Exit'}
          </Button>
          <Button
            onClick={() => onSubmit({ activate: true })}
            overrides={{
              BaseButton: ButtonOverride,
            }}
          >
            <span>{checkIfActive() ? 'SAVE' : 'ACTIVATE'}</span>
            <ButtonIconRight>
              <i className="fa-solid fa-check"></i>
            </ButtonIconRight>
          </Button>
        </div>
      </ConfigurationFooter>
    </div>
  );
};
