import { Component, OnInit, Input } from '@angular/core';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';

@Component({
  selector: 'app-step-action',
  templateUrl: './step-action.component.html',
  styleUrls: ['./step-action.component.scss'],
})
export class StepActionComponent implements OnInit {
  @Input() actionType: string;
  @Input() actionLabel: string;
  /** actionId: this input is being used to generate an
   * unique id which is being used by the parent "journey-message.component.ts"
   * to edit the position of the arror SVG in order to point it to the correct message branch preview.
   *
   * If action.type === 'message' , the value should be "action.value". Otherwise "action.id"
   * */
  @Input() actionId: string;
  @Input() showSVG?: boolean = false;
  @Input() isInvalidMessage?: boolean = false;
  @Input() isPrimary?: boolean = false;

  constructor(public twoWayService: TwoWaySharedService) {}

  ngOnInit() {}

  iconClasses(): string {
    const iconClassMap = [
      { actionType: 'hyperlink', classes: 'fa fa-link' },
      { actionType: 'call', classes: 'fa fa-phone' },
      { actionType: 'email', classes: 'fa re-email' },
      { actionType: 'collapsible', classes: 'fa re-collapsible' },
      { actionType: 'message', classes: 'fa fa-code-fork turn90' },
      { actionType: 'consent_upgrade', classes: 'fa fa-angle-double-up' },
      { actionType: 'two_way', classes: 'fa fa-comments' },
      { actionType: 'apple_chat', classes: 'fa fa-apple' },
      { actionType: 'plaid', classes: 'fa fa-money' },
      { actionType: 'form_info_capture', classes: 'fa fa-wpforms' },
    ];

    return (
      iconClassMap.find((iconClass) => iconClass.actionType === this.actionType)
        ?.classes || 'fa fa-link'
    );
  }

  showAction(): boolean {
    if (this.actionType === 'disclaimer') {
      return false;
    }
    if (this.actionType === 'two_way') {
      return this.twoWayService.showTwoWay();
    } else {
      return true;
    }
  }
}
