import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { WireClass } from '@app/core/models/message';
import { ProductGroup } from '@app/core/models/client';
import { MediaService } from '@app/core/services/media.service';
import { MediaAsset } from '@app/core/models/media';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import * as _ from 'lodash';
import { Action } from '@app/core/models/action';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'wire-preview',
  templateUrl: './wire-preview.component.html',
  styleUrls: ['./wire-preview.component.scss']
})

/**
 * 1. source of `s3_url_banner`, and as a default  branding if `wire.branding` is null
 * 2. watching this for changes
 * 3. source of `s3_url_icon` and `name` on `brandingPG`.  gets overridden when `branding` input changes
 */
export class WirePreviewComponent implements OnInit, OnChanges {
  @Input() productGroup: ProductGroup; /* [1, 2] */
  @Input() wire: WireClass; /* [2] */
  @Input() branding?: string; /* [2] */
  @Input() hasIWCU: boolean;
  @Input() showPdfMarkup: boolean = false;
  @Input() isJourneyPreview: boolean = false;
  @Output() toggleCollapsible: EventEmitter<void> = new EventEmitter<void>();
  brandingPG: ProductGroup; /* [3] */
  dateCreated = new Date();
  loading = false;
  videoShortname: string;
  featureFlags = FeatureFlags;

  constructor(public twoWayService: TwoWaySharedService,
              private mediaService: MediaService) { }

  ngOnInit() {
    if (!this.wire || !this.productGroup) { return; }
    this.initWire();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.wire || !this.productGroup ) { return; }

    // if wire & PG have changed, or init never ran (because there was no productGroup/wire) then there will be no brandingPG.
    if (changes['productGroup'] || !this.brandingPG) {
      this.initWire();
      return;
    }

    if (changes['branding'] && changes['branding']['currentValue'] !== 'create') {
      this.overrideBrandingIcon(changes['branding']['currentValue']);
    }
  }

  initWire() {
    this.initBrandingPG();
    if (this.wire.branding) {
      this.overrideBrandingIcon(this.wire.branding);
    }
    this.setupMedia();
  }

  initBrandingPG() {
    if (this.productGroup.name === 'all') {
      this.brandingPG = ProductGroup.allProductGroupPreview();
    } else {
      this.brandingPG = ProductGroup.deserialize(this.productGroup); // clones product group to prevent global changes
    }
  }
  
  getOrigin(): string {
    return window.location.origin;
  }

  overrideBrandingIcon(assetId) {
    if (assetId === null) {
      this.initBrandingPG();
      return;
    }
    this.mediaService
      .getMediaAsset(assetId, true)
      .subscribe((brand: MediaAsset) => {
        this.brandingPG.name = brand.shortname;
        this.brandingPG.branding.icon_s3_url = brand.s3_url;
      });
  }

  setupMedia(): void {
    if (this.wire.media_id && (!this.wire.video_url || !this.wire.image_url)) {
      this.mediaService.getMediaAsset(this.wire.media_id, true).subscribe(media => {
        if (media.asset_type === 'video') {
          this.wire.video_url = media.url;
          this.videoShortname = media.shortname;
        } else {
          this.wire.image_url = media.s3_url;
        }
      });
    }
  }

  hasWireBranding(): boolean {
    return !!this.wire.branding;
  }

  isSimpleAction(action): boolean {
    return _.includes(['message', 'two_way', 'plaid', 'form_info_capture'], action.type);
  }

  doAction(action: Action, ev): void {
    if (document.designMode === "on") { return; }

    if (action['type'] === 'hyperlink' && ev.target.tagName !== 'A') {
      window.open(action['value'], '_blank');
    }
    if (action['type'] === 'call') {
      window.location.href = 'tel:' + action['value'];
    }
    if (action['type'] === 'email') {
      window.location.href = encodeURI('mailto:' + action['value'] + '?subject=' + action['label']);
    }
    if (action['type'] === 'collapsible') {
      action.show = !action.show;
      this.toggleCollapsible.emit();
    }
  }

  get mediaClasses() {
    return `media media--${this.wire['media_type']}`;
  }

  // need to use a fully-qualified url because the pdf generation service we're using can't render relative links
  get videoPlaceholderUrl() {
    return `url(${this.getOrigin()}/assets/video-placeholder.png)`;
  }
}
