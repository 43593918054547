import React from 'react';
import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { COLOR } from 'baseui/badge';
import { AgentStatus } from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { Badge } from '../badge/Badge';

export type AgentAvatarProps = {
  name: string;
  status?: AgentStatus;
  imageurl?: string;
  variant?: 'small' | 'normal';
  multipleAgents?: boolean;
};

export const AgentAvatar = ({
  status = 'inactive',
  name,
  imageurl = '',
  variant = 'normal',
  multipleAgents = false,
}: AgentAvatarProps) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      })}
    >
      {variant === 'normal' && (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          })}
        >
          <Avatar
            name={name}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  outline:
                    name && name !== '?'
                      ? `${$theme.colors.warning300} solid`
                      : `#E2EFFA solid`,
                  backgroundColor:
                    name && name !== '?'
                      ? `${$theme.colors.warning300}`
                      : `#E2EFFA`,
                }),
              },
            }}
            size="scale1600"
          />
          {name && name !== '?' ? (
            <p
              className={css({
                textAlign: 'center',
                height: 'auto',
              })}
            >
              {name}
            </p>
          ) : (
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                maxWidth: '228px',
                textAlign: 'center',
              })}
            >
              <p>Unassigned</p>
              <p>Please assign an agent to the conversation</p>
            </div>
          )}

          {name !== '?' && (
            <div
              className={css({
                textTransform: 'capitalize',
              })}
            >
              <Badge
                color={status === 'active' ? COLOR.positive : COLOR.negative}
                position="left"
                status={status}
              >
                {status}
              </Badge>
            </div>
          )}
        </div>
      )}

      {variant === 'small' && (
        <div>
          <Avatar
            name={name}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  outline: `${$theme.colors.warning300} solid`,
                  backgroundColor: $theme.colors.warning300,
                }),
              },
            }}
            size="50px"
          />
        </div>
      )}
    </div>
  );
};
