export const FeatureFlags = {
  file_engine_jobs_list: 'file-engine-jobs-list',
  apple_chat: 'apple-chat',
  plaid_integration: 'plaid-integration',
  looker_maintenance: 'looker-maintenance',
  two_way_notifications: 'two-way-notifications',
  jotform_info_capture: 'jotform-info-capture',
  sso_self_service: 'sso-self-service',
  apikey_management: 'apikey-management',
  phone_management_edit_ui: 'phone-magagement-ui',
  experience_library: 'experience-library',
  relay_iq_admin: 'relay-iq-admin',
  t2e_mgmt: 't2e-management',
  push_api_client: 'push-api-client',
  new_feed: 'new-feed',
  fdic: 'fdic',
  form_submissions: 'form-submissions',
  power_ups_form_config: 'power-ups-form-config',
  report_inventory: 'report-inventory',
  new_experience_transport: 'new-experience-transport',
  hide_control_group: 'hide-control-group',
};
