import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { ArrowRight, Search } from 'baseui/icon';
import { Button, SHAPE, SIZE } from 'baseui/button';
import React from 'react';
import { Select, TYPE } from 'baseui/select';
import { ChannelsFilterOptions } from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { SearchResult } from '../relay-messenger-list/RelayMessenger.list';

export type SearchProps = {
  setIsSearchInputOpen: (value: boolean) => void;
  isSearchInputOpen: boolean;
  tagsAndCustomersList: SearchResult[];
  isAgent?: boolean;
  setChannelsFilterOptions: React.Dispatch<
    React.SetStateAction<ChannelsFilterOptions>
  >;
  searchOptions: string[];
  setSearchOptions: React.Dispatch<React.SetStateAction<string[]>>;
  channelsFilterOptions: ChannelsFilterOptions;
  inputValue?: string;
  setInputValue?: (value: string) => void;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
};

export const SearchInput = ({
  setIsSearchInputOpen,
  isSearchInputOpen,
  tagsAndCustomersList,
  searchOptions,
  setSearchOptions,
  setChannelsFilterOptions,
  channelsFilterOptions,
  inputValue,
  setInputValue,
  setOffset,
}: SearchProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const [value, setValue] = React.useState<SearchResult[]>([]);
  const placeholder = 'Search by tags or customer';
  const setSearchOption = (selectedValue: SearchResult[]) => {
    setOffset(0);
    if (selectedValue.length > 0) {
      // check that selectedValue was not already added to the list
      const selectedValueNotAdded = selectedValue.some(
        (value) =>
          (value.type === 'tag' &&
            !channelsFilterOptions.tags.includes(value.id)) ||
          (value.type === 'customer' &&
            !channelsFilterOptions.customer_ids.includes(value.id)),
      );
      if (selectedValueNotAdded) {
        const customersList = selectedValue.filter(
          (el) => el.type === 'customer',
        );
        const tagsList = selectedValue.filter((el) => el.type === 'tag');
        // should not set option id if it is already added
        setChannelsFilterOptions({
          tags: channelsFilterOptions.tags.concat(tagsList.map((el) => el.id)),
          launched_by: [],
          customer_ids: channelsFilterOptions.customer_ids.concat(
            customersList.map((el) => el.id),
          ),
        });
        const searchStringValue = selectedValue.map((el) => el.label);
        if (searchStringValue.length > 0) {
          setSearchOptions([...searchOptions, ...searchStringValue]);
        }
      }
    } else {
      setChannelsFilterOptions({
        tags: [],
        launched_by: [],
        customer_ids: [],
      });
      setSearchOptions([]);
    }
    setValue([]);
  };

  if (isSearchInputOpen) {
    return (
      <>
        <Select
          options={tagsAndCustomersList}
          value={value}
          type={TYPE.search}
          placeholder={placeholder}
          onChange={(params) => {
            setValue(params.value as SearchResult[]);
            setSearchOption(params.value as SearchResult[]);
          }}
          onInputChange={(event) => {
            setInputValue(event.currentTarget.value);
          }}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                width: `100%`,
                backgroundColor: '#FFFFFF',
                outline: `#00000`,
              }),
            },
            ControlContainer: {
              style: ({ $theme }) => ({
                backgroundColor: '#FFFFFF',
                padding: `0px`,
              }),
            },
          }}
          maxDropdownHeight="300px"
        />
        <Button
          onClick={() => setIsSearchInputOpen(false)}
          size={SIZE.compact}
          kind={'tertiary'}
          overrides={{
            BaseButton: {
              props: { 'data-testid': 'search-actions-close-btn' },
            },
          }}
        >
          Close
        </Button>
      </>
    );
  }

  if (!isSearchInputOpen) {
    return (
      <Button
        onClick={() => setIsSearchInputOpen(true)}
        size={SIZE.compact}
        shape={SHAPE.circle}
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: isSearchInputOpen
                ? '#3693BF'
                : customTheme.colors.white,
              color: isSearchInputOpen ? 'white' : '#3693BF',
              outline: 'none !important',
              ':hover': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
              ':active': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
            },
            props: { 'data-testid': 'search-actions-btn' },
          },
        }}
      >
        <Search size={28} />
      </Button>
    );
  }
};
