import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ChannelsFilterOptions,
  QueryOptionUnassignedChannels,
  TwoWayService,
  getTagNameById,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { useClientInfo } from '../../context/ClientInfoContext';
import {
  ParticipantConversation,
  TwilioConversation,
  TwoWayClosedChannel,
  TwoWayUnassignedChannel,
} from '@app/two-way/twilio-conversation.types';
import { ControlTag } from '@app/core/services/control-tags.service';
import {
  ChatToShow,
  ChatsTypes,
  RelayMessengerList,
  getCustomerInfoByChatType,
} from '../relay-messenger-list/RelayMessenger.list';
import { useStyletron } from 'styletron-react';
import { useTwilioClient } from '../../context/TwilioClientContext';
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  endOfDay,
  isWithinInterval,
  startOfDay,
  subHours,
  subMonths,
  subWeeks,
} from 'date-fns';
import { useRefreshConversations } from '../../context/RefreshConversationsContext';
import { RelayMessengerAgent } from '../agent-modal/AgentModal';
import { SearchAPIService } from '../search-api-axios-service/search-api-axios-service';

export enum RelayMessengerChatTypes {
  MyConversations = 'My Conversations',
  UnassignedConversations = 'Unassigned',
  ClosedConversations = 'Closed',
  AgentConversations = 'Agent Conversations',
}

export enum FilterMethods {
  DateCreated = 'Date created',
  LastModified = 'Last modified',
  Unread = 'Unread',
  ClosedReason = 'Closed reason',
  DateClosed = 'Date closed',
}

export enum FilterMethodOptions {
  PastDay = 'Past 24 hours',
  PastWeek = 'Past week',
  PastMonth = 'Past month',
  Custom = 'Custom',
}

export type FilterOptionDateCreatedClickedType =
  FilterMethods.DateCreated | null;
export type FilterOptionLastModifiedClickedType =
  FilterMethods.LastModified | null;
export type FilterOptionUnreadClickedType = FilterMethods.Unread | null;
export type FilterOptionClosedReasonClickedType =
  FilterMethods.ClosedReason | null;

export type FilterOptionType = FilterMethodOptions | Date[] | string | null;

export type ChatsArray = {
  setAllAssignedChats: (chats) => void;
  setAllUnassignedChats: (chats) => void;
  setClosedConversations: (chats) => void;
  allAssignedChats: TwilioConversation[];
  allUnassignedChats: TwoWayUnassignedChannel[];
  closedConversations: TwoWayClosedChannel[];
  allAgentsChats: ParticipantConversation[];
};

export type ConversationsClicked = {
  isMyConversationsClicked: boolean;
  IsMyUnassignedConversationClicked: boolean;
  IsMyClosedConversationsClicked: boolean;
  agentConversationsClicked: boolean;
};

export interface TwoWayConversationsProps {
  chatsArray: ChatsArray;
  conversationsClicked: ConversationsClicked;
  isAgentJoined: boolean;
  selectedAgentInfo: RelayMessengerAgent;
  setTotalUnnasignedChats: (totalUnnasignedChats: number) => void;
}

export type SearchOption = {
  label: string;
  id: string;
  type: 'tag' | 'customerName';
};

export const RelayMessengerConversations = ({
  chatsArray,
  conversationsClicked,
  isAgentJoined,
  selectedAgentInfo,
  setTotalUnnasignedChats,
}: TwoWayConversationsProps) => {
  const [css] = useStyletron();
  const [{ clientId }] = useClientInfo();
  const [agentTags, setAgentTags] = useState<ControlTag[]>([]);
  const [agentName, setAgentName] = useState<string>('');
  useEffect(() => {
    if (selectedAgentInfo && selectedAgentInfo.fullName) {
      setAgentName(selectedAgentInfo.fullName);
    }
  }, [selectedAgentInfo]);

  const dataTestId = conversationsClicked?.isMyConversationsClicked
    ? 'my-conversations'
    : conversationsClicked?.IsMyUnassignedConversationClicked
    ? 'unassigned-conversations'
    : conversationsClicked?.IsMyClosedConversationsClicked
    ? 'closed-conversations'
    : conversationsClicked.agentConversationsClicked
    ? 'agent-conversations'
    : '';
  const intervalRef = useRef(null);
  const { twilioClient } = useTwilioClient();
  const { refreshConversations, setRefreshConversations } =
    useRefreshConversations();

  /* SORTING METHODS */
  // CLICKED EITHER DATE CREATED/LAST MODIFIED/NAME
  const [sortingOptionNameClicked, setSortOptionNameClicked] =
    useState<string>(null);
  const [sortingOptionDateCreatedClicked, setSortOptionDateCreatedClicked] =
    useState<string>(null);
  const [sortingOptionLastModifiedClicked, setSortOptionLastModifiedClicked] =
    useState<string>(null);
  /********************************* */
  // CLICKED EITHER NEWEST/OLDEST ASCENDING/DESCENDING
  const [sortByNewestDateCreated, setSortByNewestDateCreated] =
    useState<string>(null);
  const [sortByOldestDateCreated, setSortByOldestDateCreated] =
    useState<string>(null);
  const [sortByNewestLastModified, setSortByNewestLastModified] =
    useState<string>(null);
  const [sortByOldestLastModified, setSortByOldestLastModified] =
    useState<string>(null);
  const [sortByAsc, setSortByAsc] = useState<string>(null);
  const [sortByDesc, setSortByDesc] = useState<string>(null);

  /* FILTERING METHODS */
  const [filterOptionDateCreatedClicked, setFilterOptionDateCreatedClicked] =
    useState<FilterOptionDateCreatedClickedType>(null);
  const [filterOptionLastModifiedClicked, setFilterOptionLastModifiedClicked] =
    useState<FilterOptionLastModifiedClickedType>(null);
  const [
    filterOptionUnreadMessagesClicked,
    setFilterOptionUnreadMessagesClicked,
  ] = useState<FilterOptionUnreadClickedType>(null);
  const [filterOptionClosedReasonClicked, setFilterOptionClosedReasonClicked] =
    useState<FilterOptionClosedReasonClickedType>(null);
  // Filter variants:
  const [filterByDateCreated, setFilterByDateCreated] =
    useState<FilterOptionType>(null);
  const [filterByLastModified, setFilterByLastModified] =
    useState<FilterOptionType>(null);
  const [filterByClosedReason, setFilterByClosedReason] =
    useState<FilterOptionType>(null);

  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [customerSearchResults, setCustomerSearchResults] = useState<
    SearchOption[]
  >([]);
  // state for filter options of /channels call
  const [channelsFilterOptions, setChannelsFilterOptions] =
    useState<ChannelsFilterOptions>({
      tags: [],
      launched_by: [],
      customer_ids: [],
    });
  const [pageSize, setPageSize] = useState<number>(250);
  let [offset, setOffset] = useState<number>(0);
  let [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [conversationLoadingFlag, setConversationLoadingFlag] = useState(false);

  const mapTagsAndCustomers = (
    chats:
      | TwilioConversation[]
      | TwoWayUnassignedChannel[]
      | TwoWayClosedChannel[]
      | ParticipantConversation[],
  ) => {
    let tags = [];
    const customerNames = [];
    if (chats) {
      chats.forEach((chat) => {
        let chatAttrs = chat.attrs ? chat.attrs : chat.attributes;
        if (typeof chatAttrs === 'string') {
          chatAttrs = JSON.parse(chatAttrs);
        }
        let customerName = '';
        if (
          chatAttrs &&
          chatAttrs.customer &&
          (chatAttrs.customer.first_name || chatAttrs.customer.last_name)
        ) {
          customerName =
            chatAttrs.customer.first_name + ` ` + chatAttrs.customer.last_name;
        }
        const nameFound = customerNames.find(
          (name) => name.id === customerName,
        );
        if (!nameFound) {
          customerNames.push({
            label: customerName,
            id: chatAttrs.customer.id,
            type: 'customer',
          });
        }
      });
    }

    if (agentTags) {
      tags = agentTags.map((tag, index) => {
        return { label: tag.tag_name, id: tag['id'], type: 'tag' };
      });
    }
    return tags.concat(customerNames);
  };

  const filterOptionsSelected =
    ((filterOptionDateCreatedClicked || filterOptionLastModifiedClicked) &&
      (filterByDateCreated || filterByLastModified)) ||
    filterOptionUnreadMessagesClicked ||
    (filterOptionClosedReasonClicked && filterByClosedReason);

  const sortOptionsSelected =
    (sortingOptionDateCreatedClicked &&
      (sortByNewestDateCreated || sortByOldestDateCreated)) ||
    (sortingOptionLastModifiedClicked &&
      (sortByNewestLastModified || sortByOldestLastModified)) ||
    (sortingOptionNameClicked && (sortByAsc || sortByDesc));

  const searchOptionsSelected = searchOptions.length > 0;

  const fetchAgentTags = async () => {
    const url = `/client/${clientId}/agent/tags`;
    const allAgentTags = await TwoWayService.getAgentTags(url);
    setAgentTags(allAgentTags);
  };

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
    if (pageNumber === 1) {
      setOffset(0);
    } else if (pageNumber) {
      setOffset((pageNumber - 1) * pageSize);
    }
  };

  // state to cancel the /channels call when search/filter/sort options are removed while there are still pending requests
  const [isOptionRemoved, setIsOptionRemoved] = useState(false);
  const abortUnassignedChatsControllerRef = useRef<AbortController | null>(
    null,
  );

  useEffect(() => {
    // load on click for unassigned conversations tab only
    if (conversationsClicked?.IsMyUnassignedConversationClicked) {
      fetchAllUnassignedChats(true);
    }
  }, [conversationsClicked?.IsMyUnassignedConversationClicked]);

  useEffect(() => {
    setOffset(0);
  }, [conversationsClicked.IsMyUnassignedConversationClicked]);

  const getUnnassignedQuery = (): QueryOptionUnassignedChannels => {
    let query: QueryOptionUnassignedChannels = {};
    if (filterOptionsSelected) {
      if (filterByDateCreated) {
        const { startDate, endDate } = getDateFilterRange(filterByDateCreated);
        query.dateCreatedFilterStartDate = startDate.toISOString();
        query.dateCreatedFilterEndDate = endDate.toISOString();
      }
      if (filterByLastModified) {
        const { startDate, endDate } = getDateFilterRange(filterByLastModified);
        query.lastModifiedFilterStartDate = startDate.toISOString();
        query.lastModifiedFilterEndDate = endDate.toISOString();
      }
    }
    if (sortOptionsSelected) {
      if (sortingOptionDateCreatedClicked) {
        query.dateCreatedSort = sortByNewestDateCreated ? 'newest' : 'oldest';
      } else if (sortingOptionLastModifiedClicked) {
        query.lastModifiedSort = sortByNewestLastModified ? 'newest' : 'oldest';
      } else if (sortingOptionNameClicked) {
        query.nameSort = sortByAsc ? 'ASC' : 'DESC';
      }
    }
    return query;
  };

  const fetchAllUnassignedChats = useCallback(
    async (isPageChanged?: boolean) => {
      // Abort previous request if it exists
      if (abortUnassignedChatsControllerRef.current) {
        abortUnassignedChatsControllerRef.current.abort();
      }

      let query = {};
      if (conversationsClicked?.IsMyUnassignedConversationClicked) {
        query = getUnnassignedQuery();
      }
      if (isPageChanged) {
        setConversationLoadingFlag(true);
      }
      let chatsUnassigned = null;
      if (conversationsClicked?.IsMyUnassignedConversationClicked) {
        // Create a new AbortController for the new request
        abortUnassignedChatsControllerRef.current = new AbortController();
        chatsUnassigned = await TwoWayService.getAllUnassignedChats(
          clientId,
          channelsFilterOptions,
          pageSize,
          offset,
          query,
          isOptionRemoved && abortUnassignedChatsControllerRef.current,
        );
      } else {
        chatsUnassigned = await TwoWayService.getAllUnassignedChats(
          clientId,
          {
            tags: [],
            launched_by: [],
            customer_ids: [],
          },
          pageSize,
          offset,
          query,
        );
      }
      if (chatsUnassigned) {
        setTotalCount(chatsUnassigned.count);
        setTotalUnnasignedChats(chatsUnassigned.count);
        chatsArray.setAllUnassignedChats(chatsUnassigned.data);
      }
      if (isPageChanged) {
        setConversationLoadingFlag(false);
      }
      setIsOptionRemoved(false);
    },
    [
      offset,
      channelsFilterOptions,
      pageSize,
      clientId,
      filterOptionsSelected,
      sortOptionsSelected,
      searchOptions,
    ],
  );

  const fetchAllAssignedChats = async () => {
    const chatsAssigned = await TwoWayService.getAssignedConversations(
      twilioClient,
    );
    chatsArray.setAllAssignedChats(chatsAssigned);
  };

  const fetchClosedConversations = async () => {
    const closedConversations = await TwoWayService.getClosedConversations(
      clientId,
    );
    if (closedConversations) {
      chatsArray.setClosedConversations(closedConversations);
    }
  };

  const loadAllConversations = (callAgentAgentFlag?: boolean) => {
    if (callAgentAgentFlag) {
      fetchAgentTags();
    }
    fetchAllAssignedChats();
    fetchAllUnassignedChats(true);
    fetchClosedConversations();
  };

  const recurrentConversationsCall = () => {
    fetchAllAssignedChats();
    fetchAllUnassignedChats();
  };

  useEffect(() => {
    if (clientId && twilioClient) {
      // initial call when RM loads
      loadAllConversations(true);
      intervalRef.current = setInterval(() => {
        recurrentConversationsCall();
      }, 10000);
      return () => {
        clearInterval(intervalRef.current);
        if (abortUnassignedChatsControllerRef.current) {
          abortUnassignedChatsControllerRef.current.abort();
        }
      };
    }
  }, [
    twilioClient,
    clientId,
    offset,
    filterByDateCreated,
    filterByLastModified,
    sortOptionsSelected,
    channelsFilterOptions,
    searchOptions,
  ]);

  useEffect(() => {
    // refreshConversations is truthy when agent leaves/closes a conversation
    if (refreshConversations) {
      // Adding a timeout to give the agent close/leave calls enough time to complete and update channel status
      // for the unassigned/closed conversations api calls to fetch it in the updated arrays
      setTimeout(() => {
        loadAllConversations(true);
        setRefreshConversations(false);
      }, 500);
    }
  }, [twilioClient, clientId, refreshConversations, channelsFilterOptions]);

  useEffect(() => {
    // Fetch closed conversations when:
    // - closed conversations tab is selected;
    if (conversationsClicked.IsMyClosedConversationsClicked) {
      fetchClosedConversations();
    }
  }, [clientId, conversationsClicked.IsMyClosedConversationsClicked]);

  useEffect(() => {
    // Fetch closed conversations when:
    // - length of unassigned chats array is updated, case when conversation is reopened
    if (chatsArray.allUnassignedChats.length) {
      fetchClosedConversations();
    }
  }, [clientId, chatsArray.allUnassignedChats.length]);

  const getDateFilterRange = (
    filterOption,
  ): { startDate: Date; endDate: Date } => {
    const currentDate = new Date();
    switch (filterOption) {
      case FilterMethodOptions.PastDay:
        return {
          startDate: startOfDay(subHours(currentDate, 24)),
          endDate: endOfDay(currentDate),
        };
      case FilterMethodOptions.PastWeek:
        return {
          startDate: startOfDay(subWeeks(currentDate, 1)),
          endDate: endOfDay(currentDate),
        };
      case FilterMethodOptions.PastMonth:
        return {
          startDate: startOfDay(subMonths(currentDate, 1)),
          endDate: endOfDay(currentDate),
        };
      default:
        if (Array.isArray(filterOption) && filterOption.length === 2) {
          const [startDate, endDate] = filterOption;
          return {
            startDate: startOfDay(startDate),
            endDate: endOfDay(endDate),
          };
        }
    }
  };

  const isWithinTimeFrame = (fromDate, filterOption) => {
    const currentDate = new Date();
    switch (filterOption) {
      case FilterMethodOptions.PastDay:
        return differenceInDays(currentDate, fromDate) <= 1;
      case FilterMethodOptions.PastWeek:
        return differenceInWeeks(currentDate, fromDate) <= 1;
      case FilterMethodOptions.PastMonth:
        return differenceInMonths(currentDate, fromDate) <= 1;
      default:
        // Custom date range
        if (Array.isArray(filterOption) && filterOption.length === 2) {
          const [startDate, endDate] = filterOption;
          return isWithinInterval(fromDate, {
            start: startOfDay(startDate),
            end: endOfDay(endDate),
          });
        } else {
          // No filter option selected
          return true;
        }
    }
  };
  const getCreatedDateProperty = (chat) => {
    if (chat.dateCreated) {
      return new Date(chat.dateCreated);
    } else if (chat.created_at) {
      return new Date(chat.created_at);
    }
    return null;
  };

  const getUpdatedDateProperty = (chat) => {
    if (chat.lastMessage?.dateCreated) {
      return new Date(chat.lastMessage.dateCreated);
    } else if (chat.dateUpdated) {
      return new Date(chat.dateUpdated);
    } else if (chat.updated_at) {
      return new Date(chat.updated_at);
    }
    return null;
  };

  const filterChats = useCallback(
    (chats: ChatsTypes) => {
      const chatsCopy = [...chats];
      return chatsCopy?.filter((chat) => {
        // Date created filter
        const dateCreated = getCreatedDateProperty(chat);
        const passesDateFilter = filterOptionDateCreatedClicked
          ? isWithinTimeFrame(dateCreated, filterByDateCreated)
          : true;

        // Last modified filter
        const lastModifiedDate = getUpdatedDateProperty(chat);
        const passesLastModifiedFilter = filterOptionLastModifiedClicked
          ? isWithinTimeFrame(lastModifiedDate, filterByLastModified)
          : true;

        // Unread messages filter only for assigned conversations, chat type is TwilioConversation
        let passesUnreadFilter = true;
        if (conversationsClicked?.isMyConversationsClicked) {
          const unreadCount =
            (chat as TwilioConversation)?.lastMessage?.index -
            ((chat as TwilioConversation).lastReadMessageIndex ?? -1);
          passesUnreadFilter = filterOptionUnreadMessagesClicked
            ? unreadCount > 0
            : true;
        }
        // Closed reason filter only for closed conversations if closed reason is enabled
        let passesClosedReasonFilter = true;
        if (conversationsClicked?.IsMyClosedConversationsClicked) {
          const closedReason = (chat as TwoWayClosedChannel)
            ?.latest_conversation_closed_reason;
          passesClosedReasonFilter = filterOptionClosedReasonClicked
            ? closedReason === filterByClosedReason
            : true;
        }
        return (
          passesDateFilter &&
          passesLastModifiedFilter &&
          passesUnreadFilter &&
          passesClosedReasonFilter
        );
      });
    },
    [
      filterByDateCreated,
      filterByLastModified,
      filterOptionDateCreatedClicked,
      filterOptionLastModifiedClicked,
      filterOptionUnreadMessagesClicked,
      conversationsClicked.isMyConversationsClicked,
      filterOptionClosedReasonClicked,
      filterByClosedReason,
      conversationsClicked.IsMyClosedConversationsClicked,
    ],
  );

  const filterSearchResults = useCallback(
    (chats: ChatsTypes, chatType: ChatToShow) => {
      const chatsCopy = [...chats];
      return chatsCopy?.filter((chat) => {
        const { firstName, lastName, tagId } = getCustomerInfoByChatType(
          chat,
          chatType,
        );
        const tagName = getTagNameById(agentTags, tagId);
        const tagMatches = searchOptions.includes(tagName);
        const customerNameMatches = searchOptions.includes(
          firstName + ` ` + lastName,
        );
        return tagMatches || customerNameMatches;
      });
    },
    [
      conversationsClicked.isMyConversationsClicked,
      conversationsClicked.IsMyUnassignedConversationClicked,
      conversationsClicked.IsMyClosedConversationsClicked,
      conversationsClicked.agentConversationsClicked,
      searchOptions,
      setSearchOptions,
      searchOptionsSelected,
    ],
  );

  const sortAssignedChats = useCallback(
    (chatsAssigned) => {
      if (sortingOptionDateCreatedClicked && sortByNewestDateCreated) {
        chatsAssigned = sortMyConversations(
          chatsAssigned,
          sortingOptionDateCreatedClicked,
          sortByNewestDateCreated,
        );
      } else if (sortingOptionDateCreatedClicked && sortByOldestDateCreated) {
        chatsAssigned = sortMyConversations(
          chatsAssigned,
          sortingOptionDateCreatedClicked,
          sortByOldestDateCreated,
        );
      } else if (sortingOptionLastModifiedClicked && sortByNewestLastModified) {
        chatsAssigned = sortMyConversations(
          chatsAssigned,
          sortingOptionLastModifiedClicked,
          sortByNewestLastModified,
        );
      } else if (sortingOptionLastModifiedClicked && sortByOldestLastModified) {
        chatsAssigned = sortMyConversations(
          chatsAssigned,
          sortingOptionLastModifiedClicked,
          sortByOldestLastModified,
        );
      } else if (sortingOptionNameClicked && sortByAsc) {
        chatsAssigned = sortMyConversations(
          chatsAssigned,
          sortingOptionNameClicked,
          sortByAsc,
        );
      } else if (sortingOptionNameClicked && sortByDesc) {
        chatsAssigned = sortMyConversations(
          chatsAssigned,
          sortingOptionNameClicked,
          sortByDesc,
        );
      }
      return chatsAssigned;
    },
    [
      sortOptionsSelected,
      sortingOptionDateCreatedClicked,
      sortByNewestDateCreated,
      sortByOldestDateCreated,
      sortingOptionLastModifiedClicked,
      sortByNewestLastModified,
      sortByOldestLastModified,
      sortingOptionNameClicked,
      sortByAsc,
      sortByDesc,
    ],
  );
  const sortAgentsChats = useCallback(
    (agentsChats) => {
      if (sortingOptionDateCreatedClicked && sortByNewestDateCreated) {
        agentsChats = sortAgentsConversations(
          agentsChats,
          sortingOptionDateCreatedClicked,
          sortByNewestDateCreated,
        );
      } else if (sortingOptionDateCreatedClicked && sortByOldestDateCreated) {
        agentsChats = sortAgentsConversations(
          agentsChats,
          sortingOptionDateCreatedClicked,
          sortByOldestDateCreated,
        );
      } else if (sortingOptionLastModifiedClicked && sortByNewestLastModified) {
        agentsChats = sortAgentsConversations(
          agentsChats,
          sortingOptionLastModifiedClicked,
          sortByNewestLastModified,
        );
      } else if (sortingOptionLastModifiedClicked && sortByOldestLastModified) {
        agentsChats = sortAgentsConversations(
          agentsChats,
          sortingOptionLastModifiedClicked,
          sortByOldestLastModified,
        );
      } else if (sortingOptionNameClicked && sortByAsc) {
        agentsChats = sortAgentsConversations(
          agentsChats,
          sortingOptionNameClicked,
          sortByAsc,
        );
      } else if (sortingOptionNameClicked && sortByDesc) {
        agentsChats = sortAgentsConversations(
          agentsChats,
          sortingOptionNameClicked,
          sortByDesc,
        );
      }
      return agentsChats;
    },
    [
      sortOptionsSelected,
      sortingOptionDateCreatedClicked,
      sortByNewestDateCreated,
      sortByOldestDateCreated,
      sortingOptionLastModifiedClicked,
      sortByNewestLastModified,
      sortByOldestLastModified,
      sortingOptionNameClicked,
      sortByAsc,
      sortByDesc,
    ],
  );

  const sortClosedChats = useCallback(
    (closedConversations: TwoWayClosedChannel[]) => {
      if (sortingOptionDateCreatedClicked && sortByNewestDateCreated) {
        closedConversations = sortClosedConversations(
          closedConversations,
          sortingOptionDateCreatedClicked,
          sortByNewestDateCreated,
        );
      } else if (sortingOptionDateCreatedClicked && sortByOldestDateCreated) {
        closedConversations = sortClosedConversations(
          closedConversations,
          sortingOptionDateCreatedClicked,
          sortByOldestDateCreated,
        );
      } else if (sortingOptionLastModifiedClicked && sortByNewestLastModified) {
        closedConversations = sortClosedConversations(
          closedConversations,
          sortingOptionLastModifiedClicked,
          sortByNewestLastModified,
        );
      } else if (sortingOptionLastModifiedClicked && sortByOldestLastModified) {
        closedConversations = sortClosedConversations(
          closedConversations,
          sortingOptionLastModifiedClicked,
          sortByOldestLastModified,
        );
      } else if (sortingOptionNameClicked && sortByAsc) {
        closedConversations = sortClosedConversations(
          closedConversations,
          sortingOptionNameClicked,
          sortByAsc,
        );
      } else if (sortingOptionNameClicked && sortByDesc) {
        closedConversations = sortClosedConversations(
          closedConversations,
          sortingOptionNameClicked,
          sortByDesc,
        );
      }
      return closedConversations;
    },
    [
      sortOptionsSelected,
      sortingOptionDateCreatedClicked,
      sortByNewestDateCreated,
      sortByOldestDateCreated,
      sortingOptionLastModifiedClicked,
      sortByNewestLastModified,
      sortByOldestLastModified,
      sortingOptionNameClicked,
      sortByAsc,
      sortByDesc,
    ],
  );

  const sortAgentsConversations = (
    chats: ParticipantConversation[],
    sortOption: string,
    sortMethod: string,
  ) => {
    const copyArray = [...chats];
    return copyArray.sort((a, b) => {
      if (sortMethod === 'Newest' && sortOption === 'Date created') {
        return (
          new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf()
        );
      } else if (sortMethod === 'Oldest' && sortOption === 'Date created') {
        return (
          new Date(a.dateCreated).valueOf() - new Date(b.dateCreated).valueOf()
        );
      } else if (sortMethod === 'Newest' && sortOption === 'Last modified') {
        return (
          new Date(b.dateUpdated).valueOf() - new Date(a.dateUpdated).valueOf()
        );
      } else if (sortMethod === 'Oldest' && sortOption === 'Last modified') {
        return (
          new Date(a.dateUpdated).valueOf() - new Date(b.dateUpdated).valueOf()
        );
      } else if (sortMethod === 'Ascending' && sortOption === 'Name') {
        return a['attributes'].customer.first_name.localeCompare(
          b['attributes'].customer.first_name,
        );
      } else if (sortMethod === 'Descending' && sortOption === 'Name') {
        return b['attributes'].customer.first_name.localeCompare(
          a['attributes'].customer.first_name,
        );
      }
    });
  };

  const sortMyConversations = (
    chats: TwilioConversation[],
    sortOption: string,
    sortMethod: string,
  ) => {
    const copyArray = [...chats];
    return copyArray.sort((a, b) => {
      if (sortMethod === 'Newest' && sortOption === 'Date created') {
        return (
          new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf()
        );
      } else if (sortMethod === 'Oldest' && sortOption === 'Date created') {
        return (
          new Date(a.dateCreated).valueOf() - new Date(b.dateCreated).valueOf()
        );
      } else if (sortMethod === 'Newest' && sortOption === 'Last modified') {
        return (
          new Date(b.lastMessage.dateCreated ?? b.dateUpdated).valueOf() -
          new Date(a.lastMessage.dateCreated ?? a.dateUpdated).valueOf()
        );
      } else if (sortMethod === 'Oldest' && sortOption === 'Last modified') {
        return (
          new Date(a.lastMessage.dateCreated ?? a.dateUpdated).valueOf() -
          new Date(b.lastMessage.dateCreated ?? b.dateUpdated).valueOf()
        );
      } else if (sortMethod === 'Ascending' && sortOption === 'Name') {
        return a['attributes'].customer.first_name.localeCompare(
          b['attributes'].customer.first_name,
        );
      } else if (sortMethod === 'Descending' && sortOption === 'Name') {
        return b['attributes'].customer.first_name.localeCompare(
          a['attributes'].customer.first_name,
        );
      }
    });
  };

  const sortClosedConversations = (
    chats: TwoWayClosedChannel[],
    sortOption: string,
    sortMethod: string,
  ) => {
    const copyArray = [...chats];
    return copyArray.sort((a, b) => {
      if (sortMethod === 'Newest' && sortOption === 'Date created') {
        return (
          new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
      } else if (sortMethod === 'Oldest' && sortOption === 'Date created') {
        return (
          new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
        );
      } else if (sortMethod === 'Newest' && sortOption === 'Last modified') {
        return (
          new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf()
        );
      } else if (sortMethod === 'Oldest' && sortOption === 'Last modified') {
        return (
          new Date(a.updated_at).valueOf() - new Date(b.updated_at).valueOf()
        );
      } else if (sortMethod === 'Ascending' && sortOption === 'Name') {
        return a['attrs'].customer.first_name.localeCompare(
          b['attrs'].customer.first_name,
        );
      } else if (sortMethod === 'Descending' && sortOption === 'Name') {
        return b['attrs'].customer.first_name.localeCompare(
          a['attrs'].customer.first_name,
        );
      }
    });
  };

  const tagsAndCustomersList = useMemo(() => {
    let tagsAndCustomers = [];
    if (conversationsClicked?.isMyConversationsClicked) {
      tagsAndCustomers = mapTagsAndCustomers(chatsArray.allAssignedChats);
    } else if (conversationsClicked?.IsMyUnassignedConversationClicked) {
      if (customerSearchResults.length) {
        if (agentTags.length > 0) {
          const tags = agentTags.map((tag, index) => {
            return { label: tag.tag_name, id: tag['id'], type: 'tag' };
          });
          tagsAndCustomers = tags.concat(customerSearchResults);
        } else {
          tagsAndCustomers = customerSearchResults;
        }
      } else if (
        (channelsFilterOptions.tags.length ||
          channelsFilterOptions.customer_ids.length) &&
        customerSearchResults.length === 0
      ) {
        if (agentTags) {
          const tags = agentTags.map((tag, index) => {
            return { label: tag.tag_name, id: tag['id'], type: 'tag' };
          });
          tagsAndCustomers = tags.concat(customerSearchResults);
        }
      } else {
        tagsAndCustomers = mapTagsAndCustomers(chatsArray.allUnassignedChats);
      }
    } else if (conversationsClicked?.IsMyClosedConversationsClicked) {
      tagsAndCustomers = mapTagsAndCustomers(chatsArray.closedConversations);
    } else if (conversationsClicked?.agentConversationsClicked) {
      tagsAndCustomers = mapTagsAndCustomers(chatsArray.allAgentsChats);
    }
    return tagsAndCustomers;
  }, [
    conversationsClicked.isMyConversationsClicked,
    conversationsClicked.IsMyUnassignedConversationClicked,
    conversationsClicked.IsMyClosedConversationsClicked,
    conversationsClicked.agentConversationsClicked,
    chatsArray.allAssignedChats,
    chatsArray.allUnassignedChats,
    chatsArray.closedConversations,
    chatsArray.allAgentsChats,
    customerSearchResults,
  ]);

  useEffect(() => {
    // Fetch customers by query string when:
    // - unassigned conversations tab is selected;
    // - search query changes
    const controller = new AbortController();
    if (conversationsClicked.IsMyUnassignedConversationClicked) {
      fetchCustomersBySearchQuery(controller);
      return () => {
        controller.abort();
        // bail on request when new search query is made
      };
    }
  }, [
    clientId,
    conversationsClicked.IsMyUnassignedConversationClicked,
    searchInputValue,
  ]);

  const fetchCustomersBySearchQuery = async (source) => {
    try {
      const customers = await SearchAPIService.getListOfCustomerNames(
        clientId,
        searchInputValue,
        source,
      );
      setCustomerSearchResults(customers);
    } catch (error) {
      // new search attempted, don't log this error
    }
  };

  const modifiedChatsArray = useMemo(() => {
    let result = { ...chatsArray };
    if (conversationsClicked?.isMyConversationsClicked) {
      if (filterOptionsSelected) {
        result = {
          ...result,
          allAssignedChats: filterChats(
            result.allAssignedChats,
          ) as TwilioConversation[],
        };
      }
      if (searchOptionsSelected) {
        result = {
          ...result,
          allAssignedChats: filterSearchResults(
            result.allAssignedChats,
            'My Conversations',
          ) as TwilioConversation[],
        };
      }
      if (sortOptionsSelected) {
        result = {
          ...result,
          allAssignedChats: sortAssignedChats(result.allAssignedChats),
        };
      }
    } else if (conversationsClicked?.IsMyUnassignedConversationClicked) {
      if (searchOptionsSelected) {
        result = {
          ...result,
          allUnassignedChats: filterSearchResults(
            result.allUnassignedChats,
            'Unassigned',
          ) as TwoWayUnassignedChannel[],
        };
      }
    } else if (conversationsClicked?.agentConversationsClicked) {
      if (filterOptionsSelected) {
        result = {
          ...result,
          allAgentsChats: filterChats(
            result.allAgentsChats,
          ) as ParticipantConversation[],
        };
      }
      if (searchOptionsSelected) {
        result = {
          ...result,
          allAgentsChats: filterSearchResults(
            result.allAgentsChats,
            'Agent Conversations',
          ) as ParticipantConversation[],
        };
      }
      if (sortOptionsSelected) {
        result = {
          ...result,
          allAgentsChats: sortAgentsChats(result.allAgentsChats),
        };
      }
    } else if (conversationsClicked?.IsMyClosedConversationsClicked) {
      if (filterOptionsSelected) {
        result = {
          ...result,
          closedConversations: filterChats(
            result.closedConversations,
          ) as TwoWayClosedChannel[],
        };
      }
      if (searchOptionsSelected) {
        result = {
          ...result,
          closedConversations: filterSearchResults(
            result.closedConversations,
            'Closed',
          ) as TwoWayClosedChannel[],
        };
      }
      if (sortOptionsSelected) {
        result = {
          ...result,
          closedConversations: sortClosedChats(result.closedConversations),
        };
      }
    }
    return result;
  }, [
    filterOptionsSelected,
    filterChats,
    filterSearchResults,
    sortOptionsSelected,
    sortAssignedChats,
    sortMyConversations,
    sortAgentsChats,
    conversationsClicked.isMyConversationsClicked,
    conversationsClicked.IsMyUnassignedConversationClicked,
    conversationsClicked.IsMyClosedConversationsClicked,
    conversationsClicked.agentConversationsClicked,
    chatsArray.allAssignedChats,
    chatsArray.allAgentsChats,
    chatsArray.allUnassignedChats,
    chatsArray.closedConversations,
    searchOptionsSelected,
  ]);

  return (
    <div
      className={css({
        paddingLeft: '20px',
        paddingRight: '20px',
        height: ' 100%',
        overflowY: 'scroll',
      })}
      data-testid={`${dataTestId}-component`}
      id="conversations"
    >
      {conversationsClicked?.isMyConversationsClicked &&
      chatsArray.allAssignedChats &&
      chatsArray.allAssignedChats.length ? (
        <RelayMessengerList
          chats={
            filterOptionsSelected ||
            sortOptionsSelected ||
            searchOptionsSelected
              ? modifiedChatsArray.allAssignedChats
              : chatsArray.allAssignedChats
          }
          chatType={RelayMessengerChatTypes.MyConversations}
          tagsAndCustomersList={tagsAndCustomersList}
          agentTags={agentTags}
          conversationsClicked={conversationsClicked}
          sortingName={{
            sortingOptionNameClicked,
            setSortOptionNameClicked,
            sortByAsc,
            setSortByAsc,
            sortByDesc,
            setSortByDesc,
          }}
          sortDateCreated={{
            sortingOptionDateCreatedClicked,
            setSortOptionDateCreatedClicked,
            sortByNewestDateCreated,
            setSortByNewestDateCreated,
            sortByOldestDateCreated,
            setSortByOldestDateCreated,
          }}
          sortLastModified={{
            sortingOptionLastModifiedClicked,
            setSortOptionLastModifiedClicked,
            sortByNewestLastModified,
            setSortByNewestLastModified,
            sortByOldestLastModified,
            setSortByOldestLastModified,
          }}
          isAgentJoined={isAgentJoined}
          filterDateCreated={{
            filterOptionDateCreatedClicked,
            setFilterOptionDateCreatedClicked,
            filterByDateCreated,
            setFilterByDateCreated,
          }}
          filterLastModified={{
            filterOptionLastModifiedClicked,
            setFilterOptionLastModifiedClicked,
            filterByLastModified,
            setFilterByLastModified,
          }}
          filterUnreadMessages={{
            filterOptionUnreadMessagesClicked,
            setFilterOptionUnreadMessagesClicked,
          }}
          filterClosedReason={{
            filterOptionClosedReasonClicked,
            setFilterOptionClosedReasonClicked,
            filterByClosedReason,
            setFilterByClosedReason,
          }}
          filterOptionsSelected={filterOptionsSelected}
          sortOptionsSelected={sortOptionsSelected}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          searchOptionsSelected={searchOptionsSelected}
          channelsFilterOptions={channelsFilterOptions}
          setChannelsFilterOptions={setChannelsFilterOptions}
          inputValue={searchInputValue}
          setInputValue={setSearchInputValue}
        />
      ) : conversationsClicked?.agentConversationsClicked ? (
        <RelayMessengerList
          chats={
            filterOptionsSelected ||
            sortOptionsSelected ||
            searchOptionsSelected
              ? modifiedChatsArray.allAgentsChats
              : chatsArray.allAgentsChats
          }
          chatType={RelayMessengerChatTypes.AgentConversations}
          agentTags={agentTags}
          tagsAndCustomersList={tagsAndCustomersList}
          conversationsClicked={conversationsClicked}
          sortingName={{
            sortingOptionNameClicked,
            setSortOptionNameClicked,
            sortByAsc,
            setSortByAsc,
            sortByDesc,
            setSortByDesc,
          }}
          sortDateCreated={{
            sortingOptionDateCreatedClicked,
            setSortOptionDateCreatedClicked,
            sortByNewestDateCreated,
            setSortByNewestDateCreated,
            sortByOldestDateCreated,
            setSortByOldestDateCreated,
          }}
          sortLastModified={{
            sortingOptionLastModifiedClicked,
            setSortOptionLastModifiedClicked,
            sortByNewestLastModified,
            setSortByNewestLastModified,
            sortByOldestLastModified,
            setSortByOldestLastModified,
          }}
          filterDateCreated={{
            filterOptionDateCreatedClicked,
            setFilterOptionDateCreatedClicked,
            filterByDateCreated,
            setFilterByDateCreated,
          }}
          filterLastModified={{
            filterOptionLastModifiedClicked,
            setFilterOptionLastModifiedClicked,
            filterByLastModified,
            setFilterByLastModified,
          }}
          filterUnreadMessages={{
            filterOptionUnreadMessagesClicked,
            setFilterOptionUnreadMessagesClicked,
          }}
          filterClosedReason={{
            filterOptionClosedReasonClicked,
            setFilterOptionClosedReasonClicked,
            filterByClosedReason,
            setFilterByClosedReason,
          }}
          filterOptionsSelected={filterOptionsSelected}
          sortOptionsSelected={sortOptionsSelected}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          searchOptionsSelected={searchOptionsSelected}
          agentName={agentName ? agentName : ''}
          channelsFilterOptions={channelsFilterOptions}
          setChannelsFilterOptions={setChannelsFilterOptions}
          inputValue={searchInputValue}
          setInputValue={setSearchInputValue}
        />
      ) : conversationsClicked?.IsMyUnassignedConversationClicked &&
        (chatsArray.allUnassignedChats ||
          modifiedChatsArray.allUnassignedChats) ? (
        <RelayMessengerList
          chats={
            searchOptionsSelected
              ? modifiedChatsArray.allUnassignedChats
              : chatsArray.allUnassignedChats
          }
          chatType={RelayMessengerChatTypes.UnassignedConversations}
          agentTags={agentTags}
          tagsAndCustomersList={tagsAndCustomersList}
          conversationsClicked={conversationsClicked}
          sortingName={{
            sortingOptionNameClicked,
            setSortOptionNameClicked,
            sortByAsc,
            setSortByAsc,
            sortByDesc,
            setSortByDesc,
          }}
          sortDateCreated={{
            sortingOptionDateCreatedClicked,
            setSortOptionDateCreatedClicked,
            sortByNewestDateCreated,
            setSortByNewestDateCreated,
            sortByOldestDateCreated,
            setSortByOldestDateCreated,
          }}
          sortLastModified={{
            sortingOptionLastModifiedClicked,
            setSortOptionLastModifiedClicked,
            sortByNewestLastModified,
            setSortByNewestLastModified,
            sortByOldestLastModified,
            setSortByOldestLastModified,
          }}
          filterDateCreated={{
            filterOptionDateCreatedClicked,
            setFilterOptionDateCreatedClicked,
            filterByDateCreated,
            setFilterByDateCreated,
          }}
          filterLastModified={{
            filterOptionLastModifiedClicked,
            setFilterOptionLastModifiedClicked,
            filterByLastModified,
            setFilterByLastModified,
          }}
          filterUnreadMessages={{
            filterOptionUnreadMessagesClicked,
            setFilterOptionUnreadMessagesClicked,
          }}
          filterClosedReason={{
            filterOptionClosedReasonClicked,
            setFilterOptionClosedReasonClicked,
            filterByClosedReason,
            setFilterByClosedReason,
          }}
          filterOptionsSelected={filterOptionsSelected}
          sortOptionsSelected={sortOptionsSelected}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          searchOptionsSelected={searchOptionsSelected}
          onPageChange={onPageChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalPages={Math.ceil(totalCount / pageSize)}
          totalCount={totalCount}
          showLoading={conversationLoadingFlag}
          channelsFilterOptions={channelsFilterOptions}
          setChannelsFilterOptions={setChannelsFilterOptions}
          inputValue={searchInputValue}
          setInputValue={setSearchInputValue}
          setIsOptionRemoved={setIsOptionRemoved}
          setOffset={setOffset}
        />
      ) : conversationsClicked.IsMyClosedConversationsClicked &&
        chatsArray.closedConversations &&
        chatsArray.closedConversations.length ? (
        <RelayMessengerList
          chats={
            filterOptionsSelected ||
            sortOptionsSelected ||
            searchOptionsSelected
              ? modifiedChatsArray.closedConversations
              : chatsArray.closedConversations
          }
          chatType={RelayMessengerChatTypes.ClosedConversations}
          agentTags={agentTags}
          tagsAndCustomersList={tagsAndCustomersList}
          conversationsClicked={conversationsClicked}
          sortingName={{
            sortingOptionNameClicked,
            setSortOptionNameClicked,
            sortByAsc,
            setSortByAsc,
            sortByDesc,
            setSortByDesc,
          }}
          sortDateCreated={{
            sortingOptionDateCreatedClicked,
            setSortOptionDateCreatedClicked,
            sortByNewestDateCreated,
            setSortByNewestDateCreated,
            sortByOldestDateCreated,
            setSortByOldestDateCreated,
          }}
          sortLastModified={{
            sortingOptionLastModifiedClicked,
            setSortOptionLastModifiedClicked,
            sortByNewestLastModified,
            setSortByNewestLastModified,
            sortByOldestLastModified,
            setSortByOldestLastModified,
          }}
          filterDateCreated={{
            filterOptionDateCreatedClicked,
            setFilterOptionDateCreatedClicked,
            filterByDateCreated,
            setFilterByDateCreated,
          }}
          filterLastModified={{
            filterOptionLastModifiedClicked,
            setFilterOptionLastModifiedClicked,
            filterByLastModified,
            setFilterByLastModified,
          }}
          filterUnreadMessages={{
            filterOptionUnreadMessagesClicked,
            setFilterOptionUnreadMessagesClicked,
          }}
          filterClosedReason={{
            filterOptionClosedReasonClicked,
            setFilterOptionClosedReasonClicked,
            filterByClosedReason,
            setFilterByClosedReason,
          }}
          filterOptionsSelected={filterOptionsSelected}
          sortOptionsSelected={sortOptionsSelected}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          searchOptionsSelected={searchOptionsSelected}
          channelsFilterOptions={channelsFilterOptions}
          setChannelsFilterOptions={setChannelsFilterOptions}
          inputValue={searchInputValue}
          setInputValue={setSearchInputValue}
        />
      ) : (
        <p>No conversations are available</p>
      )}
    </div>
  );
};
