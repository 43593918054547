import React from 'react';
import { useStyletron } from 'styletron-react';
import { InputBox } from '@app/api-mgmt/components/push-api/components/configurations-display/InputBox';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import {
  validateBearerTokenUri,
  validateClientIdentity,
  validateClientSecret,
  validateGrantType,
  validateName,
  validateUri,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { OAuthConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { HTTPMethodSelectBox } from '@app/api-mgmt/components/push-api/components/configurations-display/HTTPMethodSelectBox';
import { TextAreaBox } from '../configurations-display/TextAreaBox';
import { SelectBox } from '@app/api-mgmt/components/push-api/components/configurations-display/SelectBox';

export const OAuthSettings = ({
  authSettings,
  updateAuthSettings,
  integrationNames,
  integration,
}: {
  authSettings: any;
  updateAuthSettings: (value: any) => void;
  integrationNames: string[];
  integration?: OAuthConfiguration;
}) => {
  const [css] = useStyletron();

  const isExistingIntegration = integration?.uid && integration?.uid !== '';

  return (
    <div
      className={css({
        marginTop: '10px',
      })}
    >
      <>
        <Redirect
          link="https://relay-network.document360.io/docs/push-event-webhook"
          value="View Relay Integration Documentation"
        />
        <InputBox
          title="Name"
          value={authSettings.name}
          placeholder="Integration Name"
          validate={(value) => {
            return validateName(value, integration.name, integrationNames);
          }}
          onChange={(value) => updateAuthSettings({ name: value })}
          preValidate={isExistingIntegration}
        />
        <TextAreaBox
          placeholder="https://www.example.com"
          title="URI/Endpoint"
          value={authSettings.uri}
          validate={(value) => {
            return validateUri(value);
          }}
          onChange={(value) => updateAuthSettings({ uri: value })}
          preValidate={isExistingIntegration}
        />
        <SelectBox
          placeholder="Select Grant Type"
          title="Grant Type"
          validate={(value) => {
            return validateGrantType(value[0].id as string);
          }}
          options={[{ label: 'client_credentials', id: 'client_credentials' }]}
          value={
            {
              client_credentials: [
                { label: 'client_credentials', id: 'client_credentials' },
              ],
            }[authSettings.grantType]
          }
          onChange={(value) =>
            updateAuthSettings({ grantType: value[0].id as string })
          }
        />
        <InputBox
          placeholder="OAuth Client ID"
          title="Client ID"
          value={authSettings.clientIdentity}
          validate={(value) => {
            return validateClientIdentity(value);
          }}
          onChange={(value) => updateAuthSettings({ clientIdentity: value })}
          preValidate={isExistingIntegration}
        />
        <TextAreaBox
          placeholder="OAuth Client Secret"
          title="Client Secret"
          value={authSettings.clientSecret}
          validate={(value) => {
            return validateClientSecret(value);
          }}
          onChange={(value) => updateAuthSettings({ clientSecret: value })}
          hideText
          preValidate={isExistingIntegration}
        />
        <TextAreaBox
          placeholder="https://www.example.com"
          title="Authentication URL"
          value={authSettings.bearerTokenUri}
          validate={(value) => {
            return validateBearerTokenUri(value);
          }}
          onChange={(value) => updateAuthSettings({ bearerTokenUri: value })}
          preValidate={isExistingIntegration}
        />
        <TextAreaBox
          placeholder="sdhuwehu28373hwdhw828919"
          title="Token"
          value={authSettings.token}
          onChange={(value) => updateAuthSettings({ token: value })}
          isOptional
        />
        <InputBox
          placeholder="Scope"
          title="Scope"
          value={authSettings.scope}
          onChange={(value) => updateAuthSettings({ scope: value })}
          isOptional
        />
        <InputBox
          placeholder="Resource"
          title="Resource"
          value={authSettings.resource}
          onChange={(value) => updateAuthSettings({ resource: value })}
          isOptional
        />
        <HTTPMethodSelectBox
          placeholder="Select HTTP Method"
          title="HTTP Method"
          value={authSettings.method}
          onChange={(value) => updateAuthSettings({ method: value })}
        />
      </>
    </div>
  );
};
