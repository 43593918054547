import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { InputBox } from '@app/api-mgmt/components/push-api/components/configurations-display/InputBox';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import {
  validateName,
  validatePassword,
  validateUri,
  validateUsername,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { BasicAuthConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { HTTPMethodSelectBox } from '@app/api-mgmt/components/push-api/components/configurations-display/HTTPMethodSelectBox';

export const BasicAuthSettings = ({
  authSettings,
  updateAuthSettings,
  integrationNames,
  integration,
}: {
  authSettings: any;
  updateAuthSettings: (value: any) => void;
  integrationNames: string[];
  integration?: BasicAuthConfiguration;
}) => {
  const [css] = useStyletron();

  const maskedPassword = integration?.passwordMasked
    ? integration.passwordMasked
    : authSettings?.password || '';

  const [displayedPassword, setDisplayedPassword] = useState(maskedPassword);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  const isExistingIntegration = integration?.uid && integration?.uid !== '';

  return (
    <div
      className={css({
        marginTop: '10px',
      })}
    >
      <>
        <Redirect
          link="https://relay-network.document360.io/docs/push-event-webhook"
          value="View Relay Integration Documentation"
        />
        <InputBox
          title="Name"
          value={authSettings.name}
          placeholder="Integration Name"
          validate={(value) => {
            return validateName(value, integration.name, integrationNames);
          }}
          onChange={(value) => updateAuthSettings({ name: value })}
          preValidate={isExistingIntegration}
        />
        <InputBox
          placeholder="https://www.example.com"
          title="URI/Endpoint"
          value={authSettings.uri}
          validate={(value) => {
            return validateUri(value);
          }}
          onChange={(value) => updateAuthSettings({ uri: value })}
          preValidate={isExistingIntegration}
        />
        <InputBox
          placeholder="Username"
          title="Username"
          value={authSettings.username}
          validate={(value) => {
            return validateUsername(value);
          }}
          onChange={(value) => updateAuthSettings({ username: value })}
          preValidate={isExistingIntegration}
        />
        <InputBox
          placeholder="Password"
          title="Password"
          value={displayedPassword}
          validate={(value) => {
            if (value === maskedPassword && maskedPassword !== '') {
              return '';
            } else {
              return validatePassword(value);
            }
          }}
          onChange={(value) => {
            if (
              !isPasswordUpdated &&
              maskedPassword !== '' &&
              value !== maskedPassword
            ) {
              updateAuthSettings({ password: '' });
              setDisplayedPassword('');
              setIsPasswordUpdated(true);
            } else if (value !== maskedPassword) {
              updateAuthSettings({ password: value });
              setDisplayedPassword(value);
              setIsPasswordUpdated(true);
            }
          }}
          hideText={true}
          preValidate={isExistingIntegration}
        />
        <HTTPMethodSelectBox
          placeholder="Select HTTP Method"
          title="HTTP Method"
          value={authSettings.method}
          onChange={(value) => updateAuthSettings({ method: value })}
        />
      </>
    </div>
  );
};
