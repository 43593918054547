type ActionType =
  | 'message'
  | 'email'
  | 'hyperlink'
  | 'collapsible'
  | 'consent_upgrade'
  | 'call'
  | 'two_way'
  | 'plaid'
  | 'apple_chat'
  | 'form_info_capture';

export const UnSupportedSecondaryActionFeature = [
  'plaid',
  'apple_chat',
];
export class Action {
  show: boolean;
  description: string;
  id: string;
  type: ActionType;
  value: string;
  primary?: boolean;

  constructor(action) {
    Object.assign(this, action);
  }
}
